import React from "react";
import Styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useTheme } from "../components/ThemeProvider";
import { Text } from "../components/typography";
import { Theme } from "../theme/theme";

const StyledTable = Styled.table((props: { theme: Theme }) => ({
  margin: "20px 0px",
  color: props.theme.semanticColors.bodyText,
  thead: {},
  td: {
    padding: 10,
    ".skill:first-child": {
      color: props.theme.semanticColors.linkTextHot,
    },
    ":nth-child(2)": {
      maxWidth: 550,
    },
  },
}));

interface Skill {
  name: string;
  description: string;
  ability: string;
}

const skillsList: Skill[] = [
  {
    name: "Web developer tools",
    description:
      "html, css, node, npm, nvm, webpack, rollup, gulp, mocha, jest, pupateer, storybook, CSS-In-JS, styled components, emotion, jss.",
    ability: "*****",
  },
  {
    name: "React",
    description: "Seen it and done it all in this ever evoloving ecosystem. ",
    ability: "*****",
  },
  {
    name: "Angular",
    description: "Just getting started recently, Dec 2021",
    ability: "*",
  },
  {
    name: "Typescript",
    description:
      "Building single page apps, web components, node applications, and more!",
    ability: "*****",
  },
  {
    name: "Javascript",
    description:
      "These days it just feels wrong to use an untyped language. But hey, sometimes you have to do what you have to do.",
    ability: "*****",
  },
  {
    name: "Java",
    description: "Reading text books, learning code. Practicing OOP",
    ability: "**",
  },
  {
    name: "Python",
    description:
      "Wrote a compiler to translate assembly into machine language for Nand to Tetris",
    ability: "**",
  },
  {
    name: "C",
    description:
      "The first typed language I used. Only used for academic learning.",
    ability: "**",
  },
  {
    name: "Hack Assembly",
    description:
      "Assembly language used for Nand to tetris course. I don't ever again want to write another jump statement.",
    ability: "**",
  },
  {
    name: "Lua",
    description:
      "I've coded a couple of simple games in Lua: Breakout, Asteroids, and Pong",
    ability: "**",
  },
  {
    name: "OOP",
    description:
      "I've read a couple of books on the subject of OOP and earned a course certificate in Software Architecture from University of Alberta. I don't consider myself an OOP dev, but am familiar with many patterns not limited to: Command, Factory Method, Strategy, Observer, Singleton, Template Method, Decorator, and more!",
    ability: "***",
  },
  {
    name: "Design Tools",
    description:
      "Layout, Typography, UX Design, Figma, Adobe XD, Sketch, Zeppelin",
    ability: "*****",
  },
];

function SkillsTable() {
  const theme = useTheme();
  return (
    <StyledTable theme={theme}>
      <thead>
        <tr>
          {Object.keys(skillsList[0]).map((key, index) => {
            return (
              <td key={key + index}>
                <Text variant="medium_D" className="title">
                  {key}
                </Text>
              </td>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {skillsList.map((skill, index) => {
          return (
            <tr key={skill.name + index + skill.ability}>
              {Object.keys(skill).map((skillKey, index) => (
                <td key={skillKey + index}>
                  <Text
                    variant="medium_C"
                    color={
                      index === 0 ? theme.semanticColors.linkTextHot : undefined
                    }
                    className="skill"
                  >
                    {skill[skillKey]}
                  </Text>
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
}

function About(props) {
  return (
    <Layout {...props}>
      <SEO
        title="About"
        keywords={[
          `about`,
          `michael barakat`,
          `seattle`,
          `front end developer`,
          `ux developer`,
          `microsoft`,
          "engineering manager",
          "design engineer",
        ]}
      />
      <Text variant="large_C">Who am I? What do you want?</Text>
      <Text variant="medium_C">
        <Text variant="medium_D">Michael Barakat</Text> is a designer and front
        end developer / ux developer / design engineer living and working in
        Seattle, WA.
      </Text>
      <Text variant="large_B">Cold hard skills:</Text>
      <SkillsTable />
      <Text variant="large_B">But really, how and why did I get here?</Text>
      <Text variant="medium_C">
        When I'm not coding, you'll find me building something in my workshop.
        Maybe it's a woodworking project, or a pair of shoes, or it could be a
        photography project too. But why?
      </Text>
      <Text variant="medium_C">
        It's hard to say sometimes isn't it? Why do I code? Why am I here doing
        this? The short answer is:{" "}
        <Text variant="medium_D">I love building things!</Text> My whole life
        for as long as I remember, I have been fascinated by how things work and
        more especially the act of creation itself: how to create things that
        are useful, beautiful and enjoyable.
      </Text>
      <Text variant="medium_C">
        In the late 90's when I was a teenager I started getting into
        photography. Then later in college I studied film and animation and
        later worked in the film industry doing some set decoration, set
        construction, prop handling and then later motion graphics, videography,
        and video editing. This lead to my initial interest in design.
      </Text>
      <Text variant="medium_C">
        By 2011 I had moved to Seattle, hoping to land a gig someplace in
        technology. The unfortunate reality of working as a designer in
        technology however is, that you don't really get to put your hands into
        the proverbial "engine of the car." In other words you don't get "hands
        on" time with the interworkings of the machine. As a designer, you get
        to design the look and feel and interactions, but don't actually get to
        build it. The closest you get is in understanding the behaviors of a
        user, and augmenting patterns in the system to create a predictable
        outcome. As a designer, I could never get around these details. It
        bothered me to not be the builder. So, in order to overcome this
        problem, I learned how to code. The rest is history.
      </Text>
    </Layout>
  );
}

export default About;
